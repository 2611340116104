.error {
    color: #5a5c69;
    font-size: 7rem;
    position: relative;
    line-height: 1;
    width: 12.5rem;
}

.error:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 #4e73df;
    top: 0;
    color: #5a5c69;
    background: #f8f9fc;
    overflow: hidden;
    clip: rect(0,900px,0,0);
    animation: noise-anim-2 3s linear infinite alternate-reverse;
}

.error:before {
background: #ffb900;
}

.error:after {
content: attr(data-text);
position: absolute;
left: 2px;
text-shadow: -1px 0 #e74a3b;
top: 0;
color: #000000;
background: #ffbc19;
overflow: hidden;
clip: rect(0,900px,0,0);
animation: noise-anim 2s linear infinite alternate-reverse;
}


