.contact-icons {
    margin: auto;
    background: #ffffff;
    width: 168px;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    border-radius: 12%;
    padding-top: 30px;
    height: 168px;
}    
