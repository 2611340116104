html {
  scroll-behavior: smooth;
}

body{
    background-color: #ffffff!important;
    font-family: 'Open Sans', sans-serif;
    text-align: right;
    direction: rtl;
    width: 100%;
  }

.page-footer {
    border-top: 0px;
}

.dropdown-item.active, .dropdown-item:active, .nav-link.active {
  color: #5367ff;
  text-decoration: none;
}

.nav-link-cloud.active {
  color: #3dc799;
  text-decoration: none;
}


.navbarer{
  background: #fff;
}

.sectiontexter{
  padding-top: 90px;
}

@media (max-width : 767px) {
  .sectiontexter{
    padding-top: 25px; 
  }
}


.card-colorer {
    background-color: #f7f7f7;
    padding-top: 35px!important;
    padding-bottom: 35px!important;
    height: 250px;
    border-radius: 10px;
}

.glass-card-colorer {
    background-color: #e9fff8cc;
    height: 208px;
    border-radius: 0.29rem;
}

.logoer{
  margin-right: 0px;
  font-family: 'Secular One', sans-serif;
  font-size: 28px!important;
  color: #1e2167;
  padding-bottom: 0px;
  border-color: rgb(90,116,255);
  padding-top: 2px;
}

.nav-link-color {
  color: #132078;
}

.loginer{
  color: #5367ff;
  border-radius: 21px;
  background: rgba(255,255,255,0);
  font-size: 16px;
  padding-right: 20px;
  padding-left: 20px;
  height: 41px;
  margin-top: 5px;
  padding-top: 7px;
  border-color: #5367ff;
}

.registerer{
  float: right;
  background: linear-gradient(90deg,#4885ff,#5b54ff);
  border-width: 0px;
  border-radius: 21px;
  font-size: 16px;
  margin-right: 0px;
  margin-left: 0px;
  height: 41px;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 5px;
  padding-top: 7px;
}

.navbar-toggler {
    border-radius: 7px;
}

.mobiler {
  border-color: #4c7dff;
  color: #4e77ff;
  font-size: 20px;
  border-radius: 20px;
}
  

@media (min-width: 767px) {
  .header-cat-mob{
    padding-top: 89px;
  }
}
@media (max-width : 767px){
  .img-toppdaew{
    padding-top: 40px;
  }
}

.btnhm {
  background: linear-gradient(90deg,#4885ff,#5b54ff);
  border-radius: 15px;
  text-shadow: 0px 0px 8px rgba(0,0,0,0.25);
  margin-bottom: 0px;
  margin-left: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 30px;
  padding-left: 30px;
  color: rgb(255,255,255);
  border-width: 0px;
  border-color: rgba(0,0,0,0);
  font-family: Heebo, sans-serif;
  margin-top: 8px;
}

/* footer styles */
.foter-titles {
  text-align: right;
  color: rgb(0,0,0);
  font-family: Heebo, sans-serif;
  font-size: 22px;
  padding-bottom: 15px;
}


.footer-items-sub {
  text-align: right;
  color: rgb(0,0,0);
  margin-bottom: 10px;
  font-size: 18px;
}

.socials-item {
  background: rgb(36,66,222);
  font-size: 20px;
  width: 40px;
  height: 40px;
  margin-right: 3px!important;
  background: #2a5ffd!important;
}

.sticky-bottom, .sticky-top {
    position: sticky;
    right: 0;
    left: 0;
    z-index: 1030;
}
.sticky-top {
    top: 0;
}