/** */
.navbarer.scrolled {
    background-color: #fff !important;
    transition: background-color 200ms linear;
    opacity: .95;
    }
  
  
    .logoer.scrolled{
      color: #1e2167;
    }
  
    
    .nav-link-color.scrolled {
      color: #132078;
    }
  
    .loginer.scrolled{
      color: #5367ff;
      background: rgba(255,255,255,0);
      border-color: #5367ff; 
    }
  
    .registerer.scrolled {
      background: linear-gradient(90deg,#4885ff,#5b54ff);
      color: #ffffff;
    }
  
    .mobiler.scrolled{
    border-color: #5367ff;
    color: #5367ff;
    font-size: 20px;
    }